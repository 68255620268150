<script lang="ts">
	import type { ModalSettings } from '@skeletonlabs/skeleton';
	import { AppBar, Avatar, getModalStore } from '@skeletonlabs/skeleton';
	import { profileStore } from '$lib/stores';
	import { getInitials } from '$lib/utils/helper';
	import Button from '$lib/components/core/buttons/Button.svelte';
	import Link from '$lib/components/core/buttons/Link.svelte';
	import Icon from '@iconify/svelte';
	import NavigateBackButton from './buttons/NavigateBackButton.svelte';

	export let isLoggedIn = false;

	const modalStore = getModalStore();

	async function triggerSearch() {
		const modal: ModalSettings = {
			type: 'component',
			component: 'modalSearch',
			position: 'item-start'
		};
		modalStore.trigger(modal);
	}
</script>

<AppBar>
	<svelte:fragment slot="lead">
		<div class="flex flex-row space-x-2">
			<NavigateBackButton />
			<Link href="/">
				<div class="flex flex-column items-center space-x-2">
					<img alt="resonance" height="70" src="/resonance.svg" width="70" />
					<strong class="text-xl lowercase hidden md:block">resonance*</strong>
				</div>
			</Link>
		</div>
	</svelte:fragment>

	<svelte:fragment slot="trail">
		<div class="flex flex-row items-center space-x-2">
			<div class="md:inline md:ml-4">
				<Button class="btn variant-soft hover:variant-soft-primary" onClick={triggerSearch}>
					<Icon height="auto" icon="material-symbols:search" />
					<small class="hidden md:inline-block">Find new Listings</small>
				</Button>
			</div>

			{#if !isLoggedIn}
				<Link class="btn variant-filled-secondary" href="/auth/login">Login</Link>
			{:else}
				<a href="/account">
					<Avatar
						border="border-4 border-surface-300-600-token hover:!border-primary-500"
						cursor="cursor-pointer"
						initials={getInitials($profileStore?.fullName)}
					/>
				</a
				>
			{/if}
		</div>
	</svelte:fragment>
</AppBar>
