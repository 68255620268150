import type { SupabaseClient } from '@supabase/supabase-js';
import type { Profile } from '$lib/types';

export default class ProfilesRepository {
	private supabase: SupabaseClient;
	private tableName: string = 'profiles';

	constructor(supabase: SupabaseClient) {
		this.supabase = supabase;
	}

	async getById(id: string): Promise<Profile | null> {
		return this.supabase
			.from(this.tableName)
			.select('*')
			.eq('id', id)
			.single()
			.then(({ data, error }) => {
				if (error) {
					console.error('Error retrieving profile:', error);
					return null;
				}
				return {
					id: data.id,
					fullName: data.full_name,
					username: data.username
				};
			});
	}
}
