import type { MetaTagsProps } from 'svelte-meta-tags';
import { PUBLIC_SUPABASE_ANON_KEY, PUBLIC_SUPABASE_URL } from '$env/static/public';
import ProfilesRepository from '$lib/repositories/ProfilesRepository';
import type { Database } from '../lib/db';
import { createBrowserClient, createServerClient, isBrowser } from '@supabase/ssr';

export const load = async ({ url, fetch, data, depends }) => {
	depends('supabase:auth');

	const supabase = isBrowser()
		? createBrowserClient<Database>(PUBLIC_SUPABASE_URL, PUBLIC_SUPABASE_ANON_KEY, {
				global: {
					fetch
				}
			})
		: createServerClient<Database>(PUBLIC_SUPABASE_URL, PUBLIC_SUPABASE_ANON_KEY, {
				global: {
					fetch
				},
				cookies: {
					getAll() {
						return data.cookies;
					}
				}
			});

	let repository = new ProfilesRepository(supabase);
	let profile = null;
	/**
	 * It's fine to use `getSession` here, because on the client, `getSession` is
	 * safe, and on the server, it reads `session` from the `LayoutData`, which
	 * safely checked the session using `safeGetSession`.
	 */
	const {
		data: { session }
	} = await supabase.auth.getSession();

	if (session) {
		profile = repository.getById(session.user.id);
	}

	const title = 'Resonance* - Connecting the Collective';
	const description =
		'A new way to find like-minded people, produce, services and customers, without the middleman. We are a free, NZ-wide, parallel marketplace preparing for the transition to a more collaborative resource-based economy.';
	const baseMetaTags = Object.freeze({
		title: title,
		titleTemplate: '%s | Resonance',
		description: description,
		canonical: new URL(url.pathname, url.origin).href,
		openGraph: {
			type: 'website',
			url: new URL(url.pathname, url.origin).href,
			locale: 'en_NZ',
			title: title,
			description: description,
			siteName: 'Resonance'
			// images: [
			// 	{
			// 		url: 'https://www.example.ie/og-image.jpg',
			// 		alt: 'Og Image Alt',
			// 		width: 800,
			// 		height: 600,
			// 		secureUrl: 'https://www.example.ie/og-image.jpg',
			// 		type: 'image/jpeg'
			// 	}
			// ]
		}
	}) satisfies MetaTagsProps;

	return { supabase, session, profile, baseMetaTags };
};
