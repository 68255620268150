<script lang="ts">
	import { getModalStore } from '@skeletonlabs/skeleton';
	import Email from '$lib/components/core/buttons/social/Email.svelte';
	import Facebook from '$lib/components/core/buttons/social/Facebook.svelte';
	import Reddit from '$lib/components/core/buttons/social/Reddit.svelte';
	import WhatsApp from '$lib/components/core/buttons/social/WhatsApp.svelte';
	import type { Listing } from '$lib/types';

	const cBase =
		'card bg-surface-100/60 dark:bg-surface-500/30 backdrop-blur-lg overflow-hidden w-full max-w-[800px] shadow-xl mt-8 mb-auto';
	const cHeader = 'bg-surface-300-600-token flex items-center';
	('!rounded-none justify-between hover:variant-soft focus:!variant-filled-primary outline-0');
	const cFooter =
		'hidden md:flex items-center gap-2 bg-surface-300-600-token p-4 text-xs font-bold';

	const modalStore = getModalStore();
	const listing: Listing = $modalStore[0].meta.listing;
	const url = `https://resonance-arz.pages.dev/listings/${listing.id}`;
	const title = listing.title;
	const desc = listing.description;
</script>

<div class="modal-share {cBase}">
	<!-- Header -->
	<header class="modal-search-header {cHeader}">
		<div class="p-3">
			<h2 class="h2 gradient-heading">Found something awesome?</h2>
			<h4 class="h4">Share the Listing with your Friends!</h4>
		</div>
	</header>

	<div class="flex flex-row space-x-3 p-4">
		<Email body="{desc} {url}" subject={title} />
		<Reddit class="share-button" {title} {url} />
		<WhatsApp class="share-button" text="{title} {url}" />
		<Facebook class="share-button" quote={title} {url} />
	</div>

	<footer class="modal-search-footer {cFooter}">
		<div><kbd class="kbd">Esc</kbd> to close</div>
	</footer>
</div>

<style>
    .gradient-heading {
        @apply bg-clip-text text-transparent box-decoration-clone;
        /* Direction */
        @apply bg-gradient-to-br;
        /* Color Stops */
        @apply from-primary-500 via-tertiary-500 to-secondary-500;
    }
</style>
