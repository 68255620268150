<script lang="ts">
	import Icon from '@iconify/svelte';
	import { page } from '$app/stores';
	import { browser } from '$app/environment';
	import { writable } from 'svelte/store';
	import { goto } from '$app/navigation';

	// Create a store for the history stack
	const historyStack = writable<string[]>([]);

	// Update history stack when page changes
	$: if (browser && $page.url.pathname) {
		historyStack.update(stack => {
			// Don't add the same page twice in a row
			if (stack[stack.length - 1] !== $page.url.pathname) {
				return [...stack, $page.url.pathname];
			}
			return stack;
		});
	}

	let canNavigateBack = false;
	$: canNavigateBack = $historyStack.length > 1 && $page.url.pathname !== '/';

	function navigateBack() {
		historyStack.update(stack => {
			// Remove current page
			stack.pop();
			// Get previous page
			const previousPage = stack[stack.length - 1];
			// Remove it from stack (it will be added back by the navigation)
			stack.pop();
			// Navigate to it using SvelteKit's navigation
			goto(previousPage);
			return stack;
		});
	}
</script>

{#if canNavigateBack}
	<button on:click={navigateBack}>
		<Icon height="auto" icon="material-symbols:arrow-left-alt" />
	</button>
{/if}
